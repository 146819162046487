.usage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 90px);
  background-color: var(--darkBackground);
  margin-top: 90px;
}

.usage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 5vw;
  max-width: 750px;
}

.usage-title {
  text-align: center;
  font-size: 45px;
  margin-top: 45px;
  margin-bottom: 20px;
  font-weight: 600;
  overflow: hidden;
  background: linear-gradient(90deg, #79c0ff 0%, #84edc1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.usage-subtitle {
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
  overflow: hidden;
  font-weight: 400;
  color: var(--textWhite);
  opacity: 0.85;
  font-family: "Raleway";
}

@media only screen and (max-width: 850px) {
  .usage h1 {
    font-size: 35px;
  }
  .usage h3 {
    font-size: 18px;
    line-height: 22px;
  }
  .usage-container {
    margin: 0 30px;
  }
}
