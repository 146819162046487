.view-link {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: calc(100vh - 90px);
  padding-top: 90px;
  height: fit-content;
  background-color: var(--darkBackground);
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 5vw;
  max-width: 750px;
}

.view-title {
  text-align: center;
  font-size: 45px;
  margin-top: 45px;
  margin-bottom: 20px;
  font-weight: 600;
  overflow: hidden;
  background: linear-gradient(90deg, #79c0ff 0%, #84edc1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.view-subtitle {
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
  overflow: hidden;
  font-weight: 400;
  color: var(--textWhite);
  opacity: 0.85;
  font-family: "Raleway";
}

.view-message-container {
  width: 600px;
  height: 250px;
  background-color: var(--lighterBackground);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  padding: 15px 20px;
  border-collapse: separate;
  margin-bottom: 5px;
}

.view-files-container {
  width: 640px;
}

.view-button {
  display: flex;
  width: fit-content;
  height: 30px;
  align-items: center;
  align-self: center;
  justify-content: center;
  border-radius: 100px;
  padding: 20px 25px;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)),
    linear-gradient(101deg, #79c0ff, #84edc1);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px var(--darkBackground) inset;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 40px;
  overflow: hidden;
}

.view-button-text {
  background: linear-gradient(90deg, #79c0ff 0%, #84edc1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 18px;
  font-weight: 500;
}

.view-button:hover {
  transform: scale(1.05);
}

@media only screen and (max-width: 850px) {
  .view-container {
    max-width: max-content;
  }

  .view-message-container {
    width: 75vw;
  }

  .view-files-container {
    width: calc(75vw + 40px);
  }

  .view-link h1 {
    font-size: 40px;
  }

  .view-link h3 {
    font-size: 18px;
    line-height: 22px;
  }
}