input[type="checkbox"] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent;
  border: 2px var(--darkOutline) solid;
  margin-right: 10px;
  border-radius: 6px;
  padding: 2px;
  cursor: pointer;
}

.checkbox--active {
  border-color: var(--gradientGreen);
}

.checkbox-component-label {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: var(--textWhite);
  /* white-space: nowrap; */
}