.about {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh);
  background-color: var(--darkBackground);
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 5vw;
  max-width: 750px;
}

.about-title {
  text-align: center;
  font-size: 45px;
  margin-top: 45px;
  margin-bottom: 20px;
  font-weight: 600;
  overflow: hidden;
  background: linear-gradient(90deg, #79c0ff 0%, #84edc1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.about-subtitle {
  text-align: center;
  font-size: 20px;
  margin-bottom: 15px;
  overflow: hidden;
  font-weight: 400;
  color: var(--textWhite);
  opacity: 0.85;
  font-family: "Raleway";
}

.created-by-text {
  position: absolute;
  color: var(--textWhite);
  font-size: 15px;
  opacity: 0.5;
  font-family: "Raleway";
  font-weight: 400;
  bottom: 20px;
  right: 30px;
}

@media only screen and (max-width: 850px) {
  .created-by-text {
    right: auto;
    bottom: 50px;
  }

  .about h1 {
    font-size: 35px;
  }

  .about h3 {
    font-size: 18px;
    line-height: 22px;
  }

  .about-container {
    margin: 0 30px;
  }
}