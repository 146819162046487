.generate {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: calc(100vh - 90px);
  padding-top: 90px;
  background-color: var(--darkBackground);
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.generate::-webkit-scrollbar {
  display: none;
}

.generate-container {
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 5vw;
  max-width: 750px;
}

.generate-title {
  text-align: center;
  font-size: 45px;
  margin-top: 45px;
  margin-bottom: 20px;
  font-weight: 600;
  overflow: hidden;
  background: linear-gradient(90deg, #79c0ff 0%, #84edc1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.generate-subtitle {
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
  overflow: hidden;
  font-weight: 400;
  color: var(--textWhite);
  opacity: 0.85;
  font-family: "Raleway";
}

.generate-input-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
}

.generate-textarea-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  position: relative;
}

.generate-message-input {
  resize: none;
  height: fit-content;
  width: 100%;
  min-height: 180px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  background-color: var(--lighterBackground);
  font-size: 20px;
  padding: 15px 20px;
  transition: all 0.2s ease-in-out;
  color: var(--textWhite);
  border: none;
  margin-bottom: 5px;
}

.generate-message-input::placeholder,
.generate-options-input::placeholder {
  color: var(--textWhite);
  opacity: 0.2;
}

.generate-message-input:focus {
  outline: none !important;
}

.generate-file-upload-btn {
  width: 45px;
  height: 45px;
  position: absolute;
  bottom: 15px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: var(--lightBackground);
  border: none;
  cursor: pointer;
  box-shadow: 0 0 10px 2px var(--darkBackground);
  transition: all 0.2s ease-in-out;
}

.generate-upload-input {
  display: none;
}

.generate-file-upload-btn:hover {
  transform: scale(1.15);
}

.generate-upload-icon {
  width: 23px;
  height: 23px;
  margin-bottom: 3px;
}

.loading-bar {
  min-height: 4px;
}

.generate-options-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  justify-self: center;
  align-self: center;
  height: fit-content;
  margin-top: 20px;
  max-height: 100000px;
  transition: all 0.2s ease-in-out;
}

.generate-option[id="expiry"] {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.generate-options-input {
  height: fit-content;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  background-color: var(--lighterBackground);
  font-size: 16px;
  padding: 12px 18px;
  transition: all 0.2s ease-in-out;
  color: var(--textWhite);
  border: none;
  margin-bottom: 16px;
  width: fit-content;
}

.generate-options-input:focus {
  outline: none !important;
}

#generate-notify-email,
#generate-expiry-date {
  max-height: 0;
  padding: 0 18px;
  transition: all 0.2s ease-in-out;
  margin-top: 16px;
}

#generate-notify-email {
  width: 100%;
  max-width: 240px;
}

.generate-button-false,
.generate-button-true {
  display: flex;
  width: fit-content;
  height: 30px;
  align-items: center;
  align-self: center;
  justify-content: center;
  border-radius: 100px;
  padding: 20px 25px;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)),
    linear-gradient(101deg, #79c0ff, #84edc1);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px var(--darkBackground) inset;
  transition: all 0.3s ease-in-out;
  cursor: default;
  margin: 30px 0;
  opacity: 0.6;
  overflow: hidden;
}

#generate-expiry-date::-webkit-calendar-picker-indicator {
  content: "";
  display: block;
  background: url("../assets/calendar-icon.png") no-repeat center;
  background-size: 10%;
  margin: -100px;
  width: 210px;
  cursor: pointer;
}

.generate-button-false {
  cursor: pointer;
  opacity: 1;
}

.generate-button-text {
  background: linear-gradient(90deg, #79c0ff 0%, #84edc1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 18px;
  font-weight: 500;
}

.generate-button-false:hover {
  transform: scale(1.05);
}

.generated-link-container {
  display: flex;
  width: fit-content;
  max-width: 600px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: solid 3px var(--darkOutline);
  border-radius: 15px;
  padding: 0 25px;
  padding-top: 15px;
  margin-bottom: 40px;
  transition: all 0.3s ease-in-out;
}

.generated-link-container:hover {
  transform: scale(1.05);
}

.generated-link-text {
  font-size: 25px;
  color: var(--textWhite);
  overflow-x: scroll;
  pointer-events: none;
}

.Toastify__toast-theme--dark {
  --toastify-color-progress-dark: linear-gradient(rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)),
    linear-gradient(101deg, #79c0ff, #84edc1);
  --toastify-color-dark: var(--lighterBackground);
}

@media only screen and (max-width: 720px) {
  .generated-link-container {
    width: 210px;
    padding: 10px 20px;
    height: fit-content;
    overflow: hidden;
  }

  .generated-link-text {
    font-size: 20px;
    margin: 0;
    padding: 0;
    width: 210px;
    word-wrap: break-word;
    text-align: center;
  }

  .generate-options-container {
    flex-direction: column;
    padding-left: 16px;
  }

  .generate-option {
    width: 100%;
  }

  .generate-option[id="expiry"] {
    align-items: flex-start;
  }

  .generate-title {
    font-size: 35px;
  }

  .generate-subtitle {
    font-size: 18px;
    line-height: 22px;
  }

  .generate-message-input {
    font-size: 18px;
  }

  .generate button {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}