.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: var(--darkBackground);
}

.not-found-container-false {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 5vw;
  max-width: 750px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.not-found-container-true {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 5vw;
  max-width: 750px;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.not-found-title {
  text-align: center;
  font-size: 45px;
  margin-top: 45px;
  margin-bottom: 20px;
  font-weight: 600;
  overflow: hidden;
  background: linear-gradient(90deg, #79c0ff 0%, #84edc1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.not-found-subtitle {
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
  overflow: hidden;
  font-weight: 400;
  color: var(--textWhite);
  opacity: 0.85;
  font-family: "Raleway";
}

.not-found-message-container {
  width: 600px;
  height: 300px;
  margin-bottom: 50px;
  background-color: var(--lighterBackground);
  border-radius: 1.9vw;
  -webkit-border-radius: 1.9vw;
  -moz-border-radius: 1.9vw;
  padding: 15px 20px;
}

.not-found-button {
  display: flex;
  width: fit-content;
  height: 30px;
  align-items: center;
  align-self: center;
  justify-content: center;
  border-radius: 100px;
  padding: 20px 25px;
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, #79c0ff, #84edc1);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px var(--darkBackground) inset;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 10px;
  overflow: hidden;
}

.not-found-button-text {
  background: linear-gradient(90deg, #79c0ff 0%, #84edc1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 18px;
  font-weight: 500;
}

.not-found-button:hover {
  transform: scale(1.05);
}

@media only screen and (max-width: 850px) {
  .not-found h1 {
    font-size: 35px;
  }
  .not-found h3 {
    font-size: 18px;
    line-height: 22px;
  }
}
