.navbar,
.navbar-active {
  width: 100%;
  height: 90px;
  background-color: var(--lighterBackground);
  position: fixed;
  top: 0;
  z-index: 99;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.15);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.logo {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
  margin-right: 30px;
  cursor: pointer;
}

.logo-img {
  width: 35x;
  height: 35px;
  margin-right: 10px;
}

.logo-text {
  font-size: 30px;
  font-weight: bold;
  color: var(--textWhite);
}

.links,
.links-active {
  display: flex;
  width: fit-content;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  margin-left: 40px;
}

li a {
  display: inline;
  margin: 0 15px;
  height: 100%;
  line-height: 10vh;
  color: var(--textWhite);
  text-decoration: none;
  border-radius: 100px;
  padding: 7px 15px;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)),
    linear-gradient(101deg, var(--darkOutline), var(--darkOutline));
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px var(--lighterBackground) inset;
}

li a:hover {
  background-image: linear-gradient(rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)),
    linear-gradient(101deg, #79c0ff, #84edc1);
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hamburger-menu,
.hamburger-menu-active {
  display: none;
  flex-direction: column;
  margin-right: 30px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.bar1,
.bar2,
.bar3 {
  width: 30px;
  height: 2.5px;
  background-color: var(--textWhite);
  margin: 4px;
  transition: 0.5s;
  border-radius: 100px;
  transform-origin: left;
}

.hamburger-menu-active .bar1 {
  transform: rotate(45deg);
}

.hamburger-menu-active .bar2 {
  opacity: 0;
}

.hamburger-menu-active .bar3 {
  transform: rotate(-45deg);
}

.side-menu,
.side-menu-active {
  display: none;
  position: fixed;
  top: 0;
  top: -35%;
  width: 100%;
  height: 35%;
  background-color: var(--lighterBackground);
  overflow: hidden;
  z-index: 50;
  transition: 0.5s ease-in-out;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.15);
}

.side-menu-active {
  top: 90px;
}

@media only screen and (max-width: 720px) {
  .links {
    display: none;
  }

  .links-active {
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0;
    justify-content: center;
    align-items: center;
  }

  .links-active>ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
  }

  .links-active>ul>li {
    margin: 8px 0;
  }

  .links-active>ul>li>a {
    line-height: 50px;
    width: 50px;
  }

  .hamburger-menu,
  .hamburger-menu-active {
    display: flex;
    padding: 0 10px;
  }

  .navbar,
  .navbar-active {
    justify-content: space-between;
  }

  .logo {
    margin-left: 40px;
  }

  .logo-img {
    width: 30px;
    height: 30px;
  }

  .logo-text {
    font-size: 27px;
  }

  .side-menu,
  .side-menu-active {
    display: flex;
  }
}