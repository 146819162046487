.fpreview-container {
  width: 100%;
  background: var(--lighterBackground);
  margin-bottom: 5px;
  border-radius: 12px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.fpreview-container:hover {
  background: #404c5580;
}

.fpreview-name-text {
  color: var(--textWhite);
  font-size: 16px;
  font-family: "Raleway";
  opacity: 0.75;
  padding: 10px 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 0.95;
}

.fpreview-cancel-button-true,
.fpreview-cancel-button-false {
  flex: 0.05;
  opacity: 0;
  max-width: 15px;
  min-width: 15px;
  aspect-ratio: 1;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.fpreview-cancel-button-true {
  opacity: 1;
}

.fpreview-container:hover > .fpreview-cancel-button-false {
  opacity: 1;
  max-width: 15px;
  min-width: 15px;
  aspect-ratio: 1;
  margin-right: 10px;
}

@media only screen and (max-width: 850px) {
  .fpreview-cancel-button-true,
  .fpreview-cancel-button-false {
    margin-right: 18px;
  }

  .fpreview-container:hover > .fpreview-cancel-button-false {
    margin-right: 18px;
  }
}
